<template>
  <div class="list-manage rental-order">
    <section class="list-manage-table">
      <div class="table-title"><span class="title">出租订单</span></div>
      <div class="tabs-bar">
        <div
          class="tabs-item"
          :class="{ selected: index == tabsCurrent }"
          v-for="(item, index) in tabsList"
          :key="index"
          @click="tabsChange(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="thead">
        <span class="th">设备</span>
        <span class="th">租赁方式</span>
        <span class="th">数量</span>
        <span class="th">小计</span>
        <span class="th">操作</span>
      </div>
      <div class="tbody" v-loading="loading">
        <template v-if="rentalList.length !== 0"
          ><div
            class="list-item"
            v-for="rental in rentalList"
            :key="rental.order_id"
          >
            <div class="list-item-header">
              <span class="order-number">订单号：{{ rental.order_id }}</span
              ><span class="status">{{
                tabsCurrent === 4
                  ? rental.modified_order_state == 1
                    ? "修改待确认"
                    : rental.modified_order_state === 3
                    ? "修改成功"
                    : "修改失败"
                  : rental.order_state == 2
                  ? "待确认"
                  : rental.order_state == 3
                  ? "申请成功"
                  : rental.order_state == 4
                  ? "申请失败"
                  : rental.order_state == 5
                  ? "结算申请中"
                  : rental.order_state == 6
                  ? "结算成功"
                  : rental.order_state == 7
                  ? "结算失败"
                  : rental.order_state == 8
                  ? "完结申请中"
                  : rental.order_state == 9
                  ? "完结失败"
                  : rental.order_state == 10
                  ? "完结成功"
                  : rental.order_state
              }}</span>
            </div>

            <div
              class="list-item-main"
            >
              <div class="td device" @click="toOrderDetail(rental)">
                <img :src="rental.showImg" class="device-pic" alt="" />{{
                  rental.name
                }}
              </div>
              <div class="td">
                {{ rental.rental_fee }}元/{{
                  rental.rental_type == 0
                    ? "日"
                    : rental.rental_type == 1
                    ? "月"
                    : rental.rental_type == 2
                    ? "半年"
                    : "年"
                }}
              </div>
              <div class="td">×{{ rental.device_num }}</div>
              <div class="td">
                <div class="sub-total">
                  共{{ rental.device_num }}台设备，预约{{ rental.rental_time
                  }}{{
                    rental.rental_type == 0
                      ? "日"
                      : rental.rental_type == 1
                      ? "月"
                      : rental.rental_type == 2
                      ? "半年"
                      : "年"
                  }}
                  <div class="tar">
                    预计：<span class="f-orange"
                      >¥{{
                        rental.estimate_rental_fee
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="td">
                <span
                  class="btn btn-orange"
                  @click="action(rental, rental, 1)"
                  :style="
                    (tabsCurrent === 1 || tabsCurrent === 2) &&
                    (rental.order_state === 2 || rental.order_state === 8)
                      ? ''
                      : 'display:none'
                  "
                  >{{
                    rental.order_state === 2 ? "确认申请" : "确认完结"
                  }}</span
                ><span
                  class="btn"
                  @click="action(rental, rental, 0)"
                  :style="
                    (tabsCurrent === 1 || tabsCurrent === 2) &&
                    (rental.order_state === 2 || rental.order_state === 8)
                      ? ''
                      : 'display:none'
                  "
                  >{{
                    rental.order_state === 2 ? "拒绝申请" : "拒绝完结"
                  }}</span
                >
                <span
                  class="btn btn-orange"
                  @click="action(rental, rental, 1)"
                  :style="
                    tabsCurrent === 4 && rental.modified_order_state === 1
                      ? ''
                      : 'display:none'
                  "
                  >确认修改</span
                ><span
                  class="btn"
                  @click="action(rental, rental, 0)"
                  :style="
                    tabsCurrent === 4 && rental.modified_order_state === 1
                      ? ''
                      : 'display:none'
                  "
                  >拒绝修改</span
                >
              </div>
            </div>
          </div>
        </template>
        <div
          v-else-if="rentalList.length === 0 && !loading"
          style="text-align: center"
        >
          暂无数据
        </div>
      </div>
    </section>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next,jumper"
        :page-size="page.pageSize"
        :current-page="page.pageNumber"
        :total="total.totalRow"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRental, update_order, update_order1 } from "../../api/myorder";
import kits from "@/kits/index";
export default {
  data() {
    return {
      tabsCurrent: 0,
      tabsChangeindex:0,
      // tabsList: ["全部", "待确认", "完结待确认", "已完结"],
      tabsList: ["全部", "申请待确认", "完结待确认", "已完结", "修改待确认"],
      rentalList: [],
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: {
        totalPage: null,
        totalRow: null,
      },
      listState: [2, 3, 4, 5, 6, 7, 8, 9, 10],
      modifiedState: [],
      loading: false,
    };
  },
  mounted() {
    this.getrental({
      listState: this.listState,
    });
  },
  methods: {
    tabIndex(isConfirm) {
      let order_state = 0;
      switch (this.tabsCurrent) {
        case 1:
          isConfirm === 1 ? (order_state = 3) : (order_state = 4);
          break;
        case 2:
          isConfirm === 1 ? (order_state = 10) : (order_state = 9);
          break;
        default:
          break;
      }
      return order_state;
    },
    action(item, rental, flag) {
      console.log("confirm", rental, flag);
      let order_state = this.tabIndex(flag, rental.order_state);
      console.log("order_state", order_state);
      let modified_order_state;
      if (rental.modified_order_state === 1 && flag === 1) {
        modified_order_state = 3;
      } else if (rental.modified_order_state === 1 && flag === 0) {
        modified_order_state = 4;
      } else if (rental.modified_order_state === 3) {
        this.$message({
          message: "后台审核中，请稍后再试",
          type: "warning",
        });
        return;
      }
      console.log();
      this.$confirm(`确定${flag === 1 ? "确认" : "拒绝"}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (rental.modified_order_state === 0) {
            update_order({
              inputs: {
                order_id: item.order_id,
                order_state: order_state,
              },
            })
              .then((res) => {
                if (res.status === 200) {
                  this.$message({
                    message: `${flag === 1 ? "确认" : "拒绝"}成功`,
                    type: "success",
                  });
                  this.getrental({
                    listState: this.listState,
                  });
                } else if (res.status === 500) {
                  this.$message({
                    message: `未知错误，请联系客服`,
                    type: "error",
                  });
                } else {
                  this.$message({
                    message: `${flag === 1 ? "确认" : "拒绝"}失败`,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.$message({
                  message: `${err}`,
                  type: "error",
                });
              });
          } else {
            update_order1({
              inputs: {
                order_id: item.order_id,
                modified_order_state: modified_order_state,
              },
            })
              .then((res) => {
                if (res.status === 200) {
                  this.$message({
                    message: `${flag === 1 ? "确认" : "拒绝"}成功`,
                    type: "success",
                  });
                  this.getrental({
                    modified_order_state: this.modifiedState,
                  });
                } else if (res.status === 500) {
                  this.$message({
                    message: `未知错误，请联系客服`,
                    type: "error",
                  });
                } else {
                  this.$message({
                    message: `${flag === 1 ? "确认" : "拒绝"}失败`,
                    type: "error",
                  });
                }
              })
              .catch((err) => {
                this.$message({
                  message: `${err}`,
                  type: "error",
                });
              });
          }
        })
        .catch(() => {
          console.log("取消成功");
        });
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val;
      console.log("handleCurrentChange", this.listState);
      if (this.listState.length !== 0) {
        this.getrental({
          listState: this.listState,
        });
      } else {
        this.getrental({
          modified_order_state: this.modifiedState,
        });
      }
      window.scrollTo(0, 230);
    },
    tabsChange(index) {
      let newState = 0;
      let newModifiedState = [];
      this.tabsChangeindex = index
      console.log("index", index);
      if (index === 0) {
        (newState = [2, 3, 4, 5, 6, 7, 8, 9, 10]), (newModifiedState = []);
      } else if (index === 1) {
        (newState = [2]), (newModifiedState = []);
      } else if (index === 2) {
        newState = [8];
        newModifiedState = [];
      } else if (index === 3) {
        newState = [9, 10];
        newModifiedState = [];
      } else {
        newState = [];
        newModifiedState = [1, 3, 4];
      }

      this.page.pageNumber = 1;
      this.listState = newState;
      this.modifiedState = newModifiedState;
      this.tabsCurrent = index;
      console.log("tabsChange11111", this.listState, this.modifiedState);
      index === 4
        ? this.getrental({ modified_order_state: newModifiedState })
        : this.getrental({ listState: newState });
    },
    getrental({ listState, modified_order_state }) {
      // this.loading = true;
      console.log(
        "getrental listState",
        listState,
        "getrental mState",
        modified_order_state
      );
      this.loading = true;
      getRental({
        inputs: {
          type:"seller_consumer_id",
          order_state: listState,
          modified_order_state: modified_order_state,
        },
        pagination: this.page,
      }).then((res) => {
        console.log(res);
        if (res.status === 200) {
            console.log("res.data.", res.data.order_list , kits);
            var list = []
          res.data.order_list.forEach((order) => {
            order.forEach((item) => {
                console.log("item", item);
            //   let newData = JSON.parse(order.new_data);
            //   if (newData) {
            //     item.num = newData[0].num;
            //     item.rental_time = newData[0].rental_time;
            //   }
              item.showImg = kits.img.url(item.show_img);
              list.push(item)
            });
             console.log("order", order);
        });
          this.rentalList = list
          this.total.totalRow = res.data.total;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            message: `获取数据失败`,
            type: "error",
          });
        }
      });
    },
    toOrderDetail(rental) {
        if(this.tabsChangeindex==4){
            console.log("--------------toOrderDetail",rental)
            this.$router.push({
                name: "orderModifyDetails",
                query: {
                order_id: rental.order_id,
                },
            });
        }else{
            this.$router.push({
                name: "orderDetails",
                query: {
                order_id: rental.order_id,
                },
            });
        }
  
    },
  },
};
</script>

<style scoped>
</style>
